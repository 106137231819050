import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/packages/chateaudemorey/src/components/Layouts/layout.js";
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const ButtonExternal = makeShortcode("ButtonExternal");
const ButtonInternal = makeShortcode("ButtonInternal");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`Les `}<strong parentName="h1">{`Chambres d’hôtes`}</strong>{` `}<strong parentName="h1">{`Nancy`}</strong>{` `}<strong parentName="h1">{`Metz`}</strong></h1>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "960px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/28e4fc40eb91a87992ead592da6b2869/6a068/image.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": ["noopener"]
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "66.39999999999999%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAANABQDASIAAhEBAxEB/8QAFwABAQEBAAAAAAAAAAAAAAAABAABA//EABUBAQEAAAAAAAAAAAAAAAAAAAAC/9oADAMBAAIQAxAAAAFQuWxJ5Ef/xAAaEAACAwEBAAAAAAAAAAAAAAAAAgEDEiEj/9oACAEBAAEFApsfVraIUl5Y1zzP/8QAFhEAAwAAAAAAAAAAAAAAAAAAARAR/9oACAEDAQE/AaF//8QAFREBAQAAAAAAAAAAAAAAAAAAARD/2gAIAQIBAT8BZ//EABwQAAIBBQEAAAAAAAAAAAAAAAABIQIRMTNBof/aAAgBAQAGPwLKLs4iUiaUa/T/xAAeEAEAAgIBBQAAAAAAAAAAAAABABEhQTFRYXGBsf/aAAgBAQABPyG+GoekuOzkiJfgtgGR3qUaLPsz19Kf/9oADAMBAAIAAwAAABDrL//EABYRAQEBAAAAAAAAAAAAAAAAAAABEf/aAAgBAwEBPxCjY//EABcRAAMBAAAAAAAAAAAAAAAAAAABIRH/2gAIAQIBAT8QpU1n/8QAGhABAAMBAQEAAAAAAAAAAAAAAQARITFBgf/aAAgBAQABPxCyNyleNwxd0x9yB1Q6Namro50JSe7FJ9UwNEov/9k=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "chambres d’hôtes nancy",
            "title": "chambres d’hôtes nancy",
            "src": "/static/28e4fc40eb91a87992ead592da6b2869/6a068/image.jpg",
            "srcSet": ["/static/28e4fc40eb91a87992ead592da6b2869/0479a/image.jpg 250w", "/static/28e4fc40eb91a87992ead592da6b2869/41099/image.jpg 500w", "/static/28e4fc40eb91a87992ead592da6b2869/6a068/image.jpg 960w"],
            "sizes": "(max-width: 960px) 100vw, 960px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy"
          }}></img>{`
  `}</a>{`
    `}</span><strong parentName="p">{`Chambres d’hôtes proche Nancy`}</strong></p>
    <h2>{`Une `}<strong parentName="h2">{`nuit romantique`}</strong>{` dans un `}<strong parentName="h2">{`château en Lorraine`}</strong>{` ?`}</h2>
    <h3>{`Repos et calme assuré… À quelques minutes de `}<strong parentName="h3">{`Nancy`}</strong></h3>
    <p>{`Depuis 20 ans nous exerçons l’activité de `}<strong parentName="p">{`chambre d’hôtes`}</strong>{`. C’est un grand plaisir d’accueillir plus de 2 000 `}<strong parentName="p">{`voyageurs`}</strong>{` en nos lieux chaque année. `}</p>
    <p>{`Nous vous recevons afin que vous puissiez découvrir la `}<strong parentName="p">{`Lorraine`}</strong>{`, assurer un `}<strong parentName="p">{`moment de romantisme`}</strong>{`, vous échapperez de votre quotidien, ou autre, c’est avec enthousiasme que nous vous permettrons de réaliser vos plaisirs. `}</p>
    <p>{`Il est important pour le `}<strong parentName="p">{`Château de Morey`}</strong>{` de vous promettre confort et calme. Nos `}<strong parentName="p">{`chambres`}</strong>{` sont toutes parfaitement isolées de murs en pierres apparentes, elles assurent toutes un confort d’exception avec une literie de qualité. Toutes `}<strong parentName="p">{`nos chambres`}</strong>{` ont également la particularité d’être très spacieuses. `}</p>
    <ButtonExternal type="booking" mdxType="ButtonExternal">Réservez</ButtonExternal>
    <h2>{`Pour vos nuits intimes en `}<strong parentName="h2">{`Lorraine`}</strong></h2>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1000px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/e5f290bd73f3916fcf58131988aa1728/e895b/tourelle2.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": ["noopener"]
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "66.8%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAANABQDASIAAhEBAxEB/8QAFwAAAwEAAAAAAAAAAAAAAAAAAAQFAf/EABYBAQEBAAAAAAAAAAAAAAAAAAECA//aAAwDAQACEAMQAAABd2IxndIjg//EABoQAAMAAwEAAAAAAAAAAAAAAAABEQIDEiH/2gAIAQEAAQUC6wZPKa3R9FZ//8QAFREBAQAAAAAAAAAAAAAAAAAAEBH/2gAIAQMBAT8Bh//EABcRAQADAAAAAAAAAAAAAAAAAAABESH/2gAIAQIBAT8BuGP/xAAaEAACAgMAAAAAAAAAAAAAAAAAEQEhEBIx/9oACAEBAAY/AnQ8vabOn//EABsQAAMBAAMBAAAAAAAAAAAAAAABESExUWHh/9oACAEBAAE/IcNv70a2+jhlCyR4SzZhpfM//9oADAMBAAIAAwAAABBXD//EABcRAQEBAQAAAAAAAAAAAAAAAAEAESH/2gAIAQMBAT8QEnLG/8QAFhEBAQEAAAAAAAAAAAAAAAAAARAh/9oACAECAQE/EEHY/8QAHBABAAICAwEAAAAAAAAAAAAAAQARITFBUWGh/9oACAEBAAE/EE4lFLofUaiFGavtz3LAG/TmX8FoGw9iwxkZ0Br7MU7p/9k=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "chambres d’hôtes nancy",
            "title": "chambres d’hôtes nancy",
            "src": "/static/e5f290bd73f3916fcf58131988aa1728/a2510/tourelle2.jpg",
            "srcSet": ["/static/e5f290bd73f3916fcf58131988aa1728/0479a/tourelle2.jpg 250w", "/static/e5f290bd73f3916fcf58131988aa1728/41099/tourelle2.jpg 500w", "/static/e5f290bd73f3916fcf58131988aa1728/a2510/tourelle2.jpg 1000w", "/static/e5f290bd73f3916fcf58131988aa1728/c58a3/tourelle2.jpg 1500w", "/static/e5f290bd73f3916fcf58131988aa1728/3acf0/tourelle2.jpg 2000w", "/static/e5f290bd73f3916fcf58131988aa1728/e895b/tourelle2.jpg 2302w"],
            "sizes": "(max-width: 1000px) 100vw, 1000px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy"
          }}></img>{`
  `}</a>{`
    `}</span><strong parentName="p">{`chambres d’hôtes près de Nancy`}</strong></p>
    <h3>{`Cette chambre nuptiale au coeur de la `}<strong parentName="h3">{`Meurthe et Moselle`}</strong>{` vous promet un `}<strong parentName="h3">{`séjour romantique`}</strong></h3>
    <h4>{`La Tourelle pour les tourtereaux en `}<strong parentName="h4">{`chambre d’hôtes`}</strong></h4>
    <p>{`Cette `}<strong parentName="p">{`chambre`}</strong>{` située dans une des tours du `}<strong parentName="p">{`Château`}</strong>{`. Le charme de ses pierres apparentes vous transporte dans une `}<strong parentName="p">{`ambiance médiévale`}</strong>{`.
Passez votre nuit dans un `}<strong parentName="p">{`lit baldaquin`}</strong>{` de 180m, confort et calme sont assurés. `}</p>
    <p>{`Profitez d’une vue sur notre grande forêt et sur l’allée cavalière du Château. `}</p>
    <ButtonExternal type="booking" mdxType="ButtonExternal">Réservez</ButtonExternal>
    <h2>{`La préférée de la Châtelaine `}<strong parentName="h2">{`Lorraine`}</strong></h2>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1000px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/5642ba84ac9673b8d7e3612c6b9d07da/e895b/chambres-dhotes-airbnb-nancy.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": ["noopener"]
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "66.8%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAANABQDASIAAhEBAxEB/8QAFwAAAwEAAAAAAAAAAAAAAAAAAAMEAv/EABYBAQEBAAAAAAAAAAAAAAAAAAMAAf/aAAwDAQACEAMQAAABn0tWJYSh3//EABgQAAMBAQAAAAAAAAAAAAAAAAECEgAR/9oACAEBAAEFAgtJC8POsJxY6d//xAAVEQEBAAAAAAAAAAAAAAAAAAABEP/aAAgBAwEBPwEZ/8QAFREBAQAAAAAAAAAAAAAAAAAAARD/2gAIAQIBAT8BSf/EABkQAAIDAQAAAAAAAAAAAAAAAAABECExcf/aAAgBAQAGPwLg4Vms0//EABoQAQEBAAMBAAAAAAAAAAAAAAERADFRYXH/2gAIAQEAAT8hKiFFmow2F63UMhhcXL5T659t/9oADAMBAAIAAwAAABBgP//EABcRAAMBAAAAAAAAAAAAAAAAAAABETH/2gAIAQMBAT8QS8If/8QAFxEAAwEAAAAAAAAAAAAAAAAAAAERMf/aAAgBAgEBPxCGlR//xAAaEAEBAAMBAQAAAAAAAAAAAAABEQAhUTHR/9oACAEBAAE/EJrmwIUQ2YBFUiMJzuC8iatlz1Ppb6n3Hw8QdmrJgOwTlz//2Q==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "chambres d’hôtes metz",
            "title": "chambres d’hôtes metz",
            "src": "/static/5642ba84ac9673b8d7e3612c6b9d07da/a2510/chambres-dhotes-airbnb-nancy.jpg",
            "srcSet": ["/static/5642ba84ac9673b8d7e3612c6b9d07da/0479a/chambres-dhotes-airbnb-nancy.jpg 250w", "/static/5642ba84ac9673b8d7e3612c6b9d07da/41099/chambres-dhotes-airbnb-nancy.jpg 500w", "/static/5642ba84ac9673b8d7e3612c6b9d07da/a2510/chambres-dhotes-airbnb-nancy.jpg 1000w", "/static/5642ba84ac9673b8d7e3612c6b9d07da/c58a3/chambres-dhotes-airbnb-nancy.jpg 1500w", "/static/5642ba84ac9673b8d7e3612c6b9d07da/3acf0/chambres-dhotes-airbnb-nancy.jpg 2000w", "/static/5642ba84ac9673b8d7e3612c6b9d07da/e895b/chambres-dhotes-airbnb-nancy.jpg 2302w"],
            "sizes": "(max-width: 1000px) 100vw, 1000px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy"
          }}></img>{`
  `}</a>{`
    `}</span><strong parentName="p">{`chambres d’hôtes proche Metz`}</strong></p>
    <h3>{`Une nuit `}<strong parentName="h3">{`près de Nancy`}</strong>{` dans une chambre de princesse`}</h3>
    <p>{`Une `}<strong parentName="p">{`chambre spacieuse`}</strong>{` de 64m2 et son `}<strong parentName="p">{`lit baldaquin`}</strong>{` vous fera vivre une `}<strong parentName="p">{`nuit`}</strong>{` de roi et reine.
Apprécier une grande douche à l’italienne et un coin salon pour vous détendre.
Une vue suprême sur la vallée de la Natagne avec ses esquises couleurs `}<strong parentName="p">{`Lorraine`}</strong>{`. `}</p>
    <ButtonExternal type="booking" mdxType="ButtonExternal">Réservez</ButtonExternal>
    <h2>{`Un moment en famille dans la Suite Familiale pour un séjour à `}<strong parentName="h2">{`Nancy`}</strong>{` ?`}</h2>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1000px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/c3536ba51be167d9b8df18ef93761b14/e895b/suite.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": ["noopener"]
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "66.8%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAANABQDASIAAhEBAxEB/8QAFgABAQEAAAAAAAAAAAAAAAAABAAF/8QAFAEBAAAAAAAAAAAAAAAAAAAAAv/aAAwDAQACEAMQAAABOzI0C0QJD//EABkQAAMBAQEAAAAAAAAAAAAAAAABEQIDEv/aAAgBAQABBQKw57uqmJU4vwPby//EABYRAQEBAAAAAAAAAAAAAAAAAAABEf/aAAgBAwEBPwGsf//EABURAQEAAAAAAAAAAAAAAAAAAAIQ/9oACAECAQE/ATP/xAAZEAEAAgMAAAAAAAAAAAAAAAAAESEBAhD/2gAIAQEABj8CQvblMw//xAAZEAADAQEBAAAAAAAAAAAAAAAAARExIUH/2gAIAQEAAT8hfB7SE04tKXFNOaXazwBJIpp//9oADAMBAAIAAwAAABBTP//EABcRAQADAAAAAAAAAAAAAAAAAAABESH/2gAIAQMBAT8Qjiz/xAAWEQEBAQAAAAAAAAAAAAAAAAARAQD/2gAIAQIBAT8QFpqO/8QAGxABAAMBAAMAAAAAAAAAAAAAAQARIUExUWH/2gAIAQEAAT8QQ3bcSIaPc0B9iztgDBDBqfY7KSAiNjt5DA04rxP/2Q==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "chambres d’hôtes nancy",
            "title": "chambres d’hôtes nancy",
            "src": "/static/c3536ba51be167d9b8df18ef93761b14/a2510/suite.jpg",
            "srcSet": ["/static/c3536ba51be167d9b8df18ef93761b14/0479a/suite.jpg 250w", "/static/c3536ba51be167d9b8df18ef93761b14/41099/suite.jpg 500w", "/static/c3536ba51be167d9b8df18ef93761b14/a2510/suite.jpg 1000w", "/static/c3536ba51be167d9b8df18ef93761b14/c58a3/suite.jpg 1500w", "/static/c3536ba51be167d9b8df18ef93761b14/3acf0/suite.jpg 2000w", "/static/c3536ba51be167d9b8df18ef93761b14/e895b/suite.jpg 2302w"],
            "sizes": "(max-width: 1000px) 100vw, 1000px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy"
          }}></img>{`
  `}</a>{`
    `}</span><strong parentName="p">{`chambres d’hôtes près de Nancy`}</strong></p>
    <h3>{`Appréciez votre moment en `}<strong parentName="h3">{`famille`}</strong>{` en `}<strong parentName="h3">{`Lorraine`}</strong>{` à proximité de `}<strong parentName="h3">{`Nancy`}</strong>{` et `}<strong parentName="h3">{`Metz`}</strong></h3>
    <p>{`Le `}<strong parentName="p">{`Château`}</strong>{` vous accueille en couple comme en `}<strong parentName="p">{`famille`}</strong>{`, en mettant à disposition une majestueuse `}<strong parentName="p">{`chambre`}</strong>{` pour votre `}<strong parentName="p">{`couple`}</strong>{` accompagnée d’une `}<strong parentName="p">{`chambre`}</strong>{` annexe pour `}<strong parentName="p">{`vos enfants`}</strong>{`. `}</p>
    <p>{`Vous profiterez d’une salle de bain spacieuse et d’une vue imprenable sur `}<strong parentName="p">{`l’église romane du XII siècle`}</strong>{` et la `}<strong parentName="p">{`grande vallée Lorraine`}</strong>{`.`}</p>
    <ButtonExternal type="booking" mdxType="ButtonExternal">Réservez</ButtonExternal>
    <h2>{`Douce Printanière, douce `}<strong parentName="h2">{`Lorraine`}</strong></h2>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1000px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/1cf0c9321a39b0681be39d8ea400489d/e895b/printaniere.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": ["noopener"]
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "66.8%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAANABQDASIAAhEBAxEB/8QAGAAAAwEBAAAAAAAAAAAAAAAAAAQFAgP/xAAWAQEBAQAAAAAAAAAAAAAAAAABAgP/2gAMAwEAAhADEAAAAdMTG875kcT/xAAbEAACAgMBAAAAAAAAAAAAAAABAgARAwQSE//aAAgBAQABBQJZ0ebCzG5U7FeM/8QAFhEBAQEAAAAAAAAAAAAAAAAAAAER/9oACAEDAQE/Aa1//8QAFxEBAAMAAAAAAAAAAAAAAAAAAAECEf/aAAgBAgEBPwGsMf/EABoQAAICAwAAAAAAAAAAAAAAAAABEEEREmH/2gAIAQEABj8CF0wa0Ko//8QAGhABAAMBAQEAAAAAAAAAAAAAAQARQSFRkf/aAAgBAQABPyF0KIgexbgAqu5cbH2blBSBZcnXZ//aAAwDAQACAAMAAAAQSP8A/8QAFxEBAQEBAAAAAAAAAAAAAAAAAREAIf/aAAgBAwEBPxBnFzTZv//EABgRAAIDAAAAAAAAAAAAAAAAAAABESFh/9oACAECAQE/EKGpFs//xAAYEAEBAQEBAAAAAAAAAAAAAAABESEAUf/aAAgBAQABPxDWXE4VSamuUgouY5YQq4+L2As9QCedFq73/9k=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "chambres d’hôtes",
            "title": "chambres d’hôtes",
            "src": "/static/1cf0c9321a39b0681be39d8ea400489d/a2510/printaniere.jpg",
            "srcSet": ["/static/1cf0c9321a39b0681be39d8ea400489d/0479a/printaniere.jpg 250w", "/static/1cf0c9321a39b0681be39d8ea400489d/41099/printaniere.jpg 500w", "/static/1cf0c9321a39b0681be39d8ea400489d/a2510/printaniere.jpg 1000w", "/static/1cf0c9321a39b0681be39d8ea400489d/c58a3/printaniere.jpg 1500w", "/static/1cf0c9321a39b0681be39d8ea400489d/3acf0/printaniere.jpg 2000w", "/static/1cf0c9321a39b0681be39d8ea400489d/e895b/printaniere.jpg 2302w"],
            "sizes": "(max-width: 1000px) 100vw, 1000px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy"
          }}></img>{`
  `}</a>{`
    `}</span><strong parentName="p">{`Chambres d’hôtes`}</strong></p>
    <h3>{`Un séjour en `}<strong parentName="h3">{`chambre d’hôtes`}</strong>{` au couleur chaude des beaux jours`}</h3>
    <p>{`Reposez-vous dans cette `}<strong parentName="p">{`chambre solaire`}</strong>{` avec sa décoration qui lui donne des airs de Toscane.
Vous apprécierez sa vue panoramique sur le `}<strong parentName="p">{`parc arboré du Château`}</strong>{` et la `}<strong parentName="p">{`vallée de Meurthe et Moselle`}</strong>{`.
Une grande salle de bain avec un spacieux coin salon vous permettront de vous relaxer lors de votre `}<strong parentName="p">{`voyage`}</strong>{` entre `}<strong parentName="p">{`Nancy et Metz`}</strong>{`.  `}</p>
    <ButtonExternal type="booking" mdxType="ButtonExternal">Réservez</ButtonExternal>
    <h2>{`S’envoler dans la Colvert`}</h2>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1000px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/6a87ca6c1ae4eb723ab482e7d9b55771/e895b/colvert.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": ["noopener"]
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "66.8%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAANABQDASIAAhEBAxEB/8QAGAAAAgMAAAAAAAAAAAAAAAAAAAUBAwT/xAAWAQEBAQAAAAAAAAAAAAAAAAACAAH/2gAMAwEAAhADEAAAAd9qWA24vLf/xAAZEAACAwEAAAAAAAAAAAAAAAABAgAREhP/2gAIAQEAAQUCuizhTvU6sJ1LtRn/xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAEDAQE/AT//xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAECAQE/AT//xAAZEAACAwEAAAAAAAAAAAAAAAAAEQECMSH/2gAIAQEABj8CQh1welWctMH/xAAaEAEAAwADAAAAAAAAAAAAAAABABEhQVFh/9oACAEBAAE/IbZrgyuvsALB8Qt5o88xo0p6iWEdE//aAAwDAQACAAMAAAAQmM//xAAYEQEAAwEAAAAAAAAAAAAAAAABABEhUf/aAAgBAwEBPxB1tJnJ/8QAGBEAAgMAAAAAAAAAAAAAAAAAADEBEWH/2gAIAQIBAT8QhMrT/8QAGhABAQADAQEAAAAAAAAAAAAAAREAITFhkf/aAAgBAQABPxB1LxC724+S8mPuEE7jxiithQ1j1xKGshiHZc0J/Ehn/9k=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "chambres d’hôtes",
            "title": "chambres d’hôtes",
            "src": "/static/6a87ca6c1ae4eb723ab482e7d9b55771/a2510/colvert.jpg",
            "srcSet": ["/static/6a87ca6c1ae4eb723ab482e7d9b55771/0479a/colvert.jpg 250w", "/static/6a87ca6c1ae4eb723ab482e7d9b55771/41099/colvert.jpg 500w", "/static/6a87ca6c1ae4eb723ab482e7d9b55771/a2510/colvert.jpg 1000w", "/static/6a87ca6c1ae4eb723ab482e7d9b55771/c58a3/colvert.jpg 1500w", "/static/6a87ca6c1ae4eb723ab482e7d9b55771/3acf0/colvert.jpg 2000w", "/static/6a87ca6c1ae4eb723ab482e7d9b55771/e895b/colvert.jpg 2302w"],
            "sizes": "(max-width: 1000px) 100vw, 1000px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy"
          }}></img>{`
  `}</a>{`
    `}</span><strong parentName="p">{`Chambres d’hôtes`}</strong></p>
    <h3>{`Le symbole de la `}<strong parentName="h3">{`Lorraine`}</strong>{` vous transportera dans un moment authentique`}</h3>
    <p>{`Offrez-vous une nuit dans une `}<strong parentName="p">{`chambre`}</strong>{` de 43m2 avec son `}<strong parentName="p">{`grand lit`}</strong>{` de 200x200.
Vous y retrouverez une baignoire pour vous permettre de vous détendre.
Cette `}<strong parentName="p">{`chambre`}</strong>{` vous fera également profiter de la vue sur la vallée et sur le parc du `}<strong parentName="p">{`Château`}</strong>{`.
Appréciez le charme de cette `}<strong parentName="p">{`chambre`}</strong>{` qui reflète `}<strong parentName="p">{`l’authenticité de la Lorraine`}</strong>{`.`}</p>
    <ButtonExternal type="booking" mdxType="ButtonExternal">Réservez</ButtonExternal>
    <h2>{`Déjeuner en toute tranquillité`}</h2>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1000px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/ba3ef274c3ef583c5369d5411d433412/e895b/pdj.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": ["noopener"]
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "66.8%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAANABQDASIAAhEBAxEB/8QAFgABAQEAAAAAAAAAAAAAAAAABAAD/8QAFgEBAQEAAAAAAAAAAAAAAAAAAQID/9oADAMBAAIQAxAAAAFuRLOkQIf/xAAaEAEBAAIDAAAAAAAAAAAAAAACAQAhAxEy/9oACAEBAAEFAgysb1d4RBl93lvf/8QAFREBAQAAAAAAAAAAAAAAAAAAARD/2gAIAQMBAT8BJ//EABURAQEAAAAAAAAAAAAAAAAAAAEQ/9oACAECAQE/AWf/xAAbEAEAAQUBAAAAAAAAAAAAAAAAARExUWGB4f/aAAgBAQAGPwKdJo9ZdWh//8QAGhAAAgMBAQAAAAAAAAAAAAAAAAERITFRgf/aAAgBAQABPyGuPggbK8JtKawNabadKw3Fmw/w/9oADAMBAAIAAwAAABBk/wD/xAAWEQEBAQAAAAAAAAAAAAAAAAABABH/2gAIAQMBAT8QBa3/xAAWEQEBAQAAAAAAAAAAAAAAAAABABH/2gAIAQIBAT8QTYX/xAAcEAEBAAICAwAAAAAAAAAAAAABEQAhMWFBUaH/2gAIAQEAAT8QMZFKvWSSERhu+5iDDR4GChDS1HnJSAUg2pI/eMpKDva11zn/2Q==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "chambres d’hôtes petit déjeuner",
            "title": "chambres d’hôtes petit déjeuner",
            "src": "/static/ba3ef274c3ef583c5369d5411d433412/a2510/pdj.jpg",
            "srcSet": ["/static/ba3ef274c3ef583c5369d5411d433412/0479a/pdj.jpg 250w", "/static/ba3ef274c3ef583c5369d5411d433412/41099/pdj.jpg 500w", "/static/ba3ef274c3ef583c5369d5411d433412/a2510/pdj.jpg 1000w", "/static/ba3ef274c3ef583c5369d5411d433412/c58a3/pdj.jpg 1500w", "/static/ba3ef274c3ef583c5369d5411d433412/3acf0/pdj.jpg 2000w", "/static/ba3ef274c3ef583c5369d5411d433412/e895b/pdj.jpg 2302w"],
            "sizes": "(max-width: 1000px) 100vw, 1000px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy"
          }}></img>{`
  `}</a>{`
    `}</span><strong parentName="p">{`Chambres d’hôtes Petit Déjeuner`}</strong></p>
    <h3>{`Un moment d’intimité au `}<strong parentName="h3">{`petit matin Lorrain`}</strong>{` assuré.`}</h3>
    <p>{`Suite à la `}<strong parentName="p">{`crise COVID-19`}</strong>{` nous adaptons notre formule de `}<strong parentName="p">{`petit-déjeuner`}</strong>{`.
Celui-ci est maintenant `}<strong parentName="p">{`disponible en chambre`}</strong>{`. Nous avons aménagé dans toutes nos `}<strong parentName="p">{`chambres`}</strong>{` un espace pour que vous puissiez prendre votre `}<strong parentName="p">{`petit déjeuner en chambre`}</strong>{`.
Nous pouvons également vous servir `}<strong parentName="p">{`un repas en chambre`}</strong>{` si besoin le soir. `}</p>
    <p>{`Voici la formule : `}</p>
    <ul>
      <li parentName="ul">{`Boissons chaudes ☕`}</li>
      <li parentName="ul">{`Viennoiseries 🍞 `}</li>
      <li parentName="ul">{`Jus d’orange bio 🍊`}</li>
      <li parentName="ul">{`Yaourt et fruit 🍶🍐🍏`}</li>
    </ul>
    <p>{`Vous apprécieriez ce `}<strong parentName="p">{`petit déjeuner offert`}</strong>{` qui est composés de `}<strong parentName="p">{`produits locaux et Lorrain`}</strong>{`. `}</p>
    <p>{`Il était important pour nous d’entreprendre des solutions pour continuer à vous recevoir dans notre demeure à `}<strong parentName="p">{`quelques minutes de Nancy`}</strong>{`, tout en sécurité. `}</p>
    <h2>{`Les petits détails sont souvent appréciés`}</h2>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "640px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/2b4e4e518b5a879bf4410b0cf46bc522/c08c5/chateau_de_morey_mariage.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": ["noopener"]
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "66.39999999999999%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAANABQDASIAAhEBAxEB/8QAGAAAAwEBAAAAAAAAAAAAAAAAAAIDAQT/xAAVAQEBAAAAAAAAAAAAAAAAAAAAAf/aAAwDAQACEAMQAAAB7UxSpAk//8QAGRAAAgMBAAAAAAAAAAAAAAAAARAAAhEh/9oACAEBAAEFAhsFuLF//8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAwEBPwE//8QAFREBAQAAAAAAAAAAAAAAAAAAACH/2gAIAQIBAT8BV//EABkQAAIDAQAAAAAAAAAAAAAAAAABESAxUf/aAAgBAQAGPwJyPtNZ/8QAGxAAAgIDAQAAAAAAAAAAAAAAAAERITFBcWH/2gAIAQEAAT8hfw6GdFDlkVkYh6Vw/9oADAMBAAIAAwAAABAE7//EABcRAQADAAAAAAAAAAAAAAAAAAAhQVH/2gAIAQMBAT8QpOP/xAAXEQADAQAAAAAAAAAAAAAAAAAAESFR/9oACAECAQE/EFR4P//EABwQAQEAAgIDAAAAAAAAAAAAAAERACExUWFxkf/aAAgBAQABPxAS42+MKIilLrWsUVNyPvFoKnTkW014y/AdKGf/2Q==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "chambres d’hôtes entre Nancy et Metz",
            "title": "chambres d’hôtes entre Nancy et Metz",
            "src": "/static/2b4e4e518b5a879bf4410b0cf46bc522/c08c5/chateau_de_morey_mariage.jpg",
            "srcSet": ["/static/2b4e4e518b5a879bf4410b0cf46bc522/0479a/chateau_de_morey_mariage.jpg 250w", "/static/2b4e4e518b5a879bf4410b0cf46bc522/41099/chateau_de_morey_mariage.jpg 500w", "/static/2b4e4e518b5a879bf4410b0cf46bc522/c08c5/chateau_de_morey_mariage.jpg 640w"],
            "sizes": "(max-width: 640px) 100vw, 640px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy"
          }}></img>{`
  `}</a>{`
    `}</span><strong parentName="p">{`Chambres d’hôtes entre Nancy et Metz`}</strong></p>
    <h3>{`Vous ne manquerez de rien lors de votre séjour entre `}<strong parentName="h3">{`Nancy et Metz`}</strong></h3>
    <p>{`On sait que cela compte, les équipements de nos `}<strong parentName="p">{`chambres`}</strong>{` sont là pour perfectionner votre `}<strong parentName="p">{`voyage`}</strong>{` en `}<strong parentName="p">{`Meurthe et Moselle`}</strong>{`, `}<strong parentName="p">{`Lorraine`}</strong>{` ou `}<strong parentName="p">{`Nancy`}</strong>{`.
Retrouvez dans toutes nos `}<strong parentName="p">{`chambres`}</strong>{` : `}</p>
    <ul>
      <li parentName="ul">{`Accès wifi 🌐`}</li>
      <li parentName="ul">{`Sèche cheveux 💇‍♀`}</li>
      <li parentName="ul">{`Gel douche & shampoing 🚿`}</li>
      <li parentName="ul">{`Serviettes de bain & peignoirs 💆‍♀`}</li>
      <li parentName="ul">{`Documentation sur la Lorraine 📜`}</li>
      <li parentName="ul">{`Coin salon 🛋`}</li>
    </ul>
    <p>{`Émerveillez votre moment près de `}<strong parentName="p">{`Nancy et Metz`}</strong>{` avec de multiples activités dans notre établissement, comme SPA, massage, piscine, balade à cheval…`}</p>
    <ButtonInternal to="/chambres-dhotes/airbnb-nancy-metz" mdxType="ButtonInternal">En savoir plus</ButtonInternal>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      